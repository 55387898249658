import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Datetime from '@/components/core/DatePicker';
import { Box, Button, Group, VStack, Flex, Text, Grid } from '@chakra-ui/react';
// Local Deps
import {
  checkForAsianGrinder,
  convertToCamelCase,
  correctDecimalRoundingBlur,
  correctDecimalRoundingChange,
} from '../../../../helpers';
import { productionDateValidations, shipmentDateValidations } from '../../../../utils';
import SelectField from '../../../basic/SelectField';
import NumberSpinnerInput from '../../../form/NumberSpinnerInput/NumberSpinnerInput';
import DateSection from '../DateSection/DateSection';
import { createNewLine } from '../InternationalOrderForm/helpers';
import InternationalOrderLines from '../InternationalOrderLines';
import InternationalOrderRecurringFields from '../InternationalOrderRecurringFields/InternationalOrderRecurringFields';
import './PurchaseOrderCard.scss';
import { TimePicker } from 'antd';
import CustomFormControl from '../../../core/CustomFormControl';
import { ReactComponent as CopyIconSVG } from '../../../../assets/copy-icon.svg';
import { intersection, isEqual } from 'lodash';
import POFormFieldLabel from '../edit-form/POFormFieldLabel';
import { connect } from 'react-redux';
import { findEntitiesInState } from '../../../../slices/masterData/entityManagerSlice';
import { Alert } from '../../../ui/alert';
import {
  PopoverRoot,
  PopoverContent,
  PopoverBody,
  PopoverTrigger,
  PopoverFooter,
  PopoverCloseTrigger,
} from '@/components/ui/popover';

class PurchaseOrderCard extends Component {
  static propTypes = {
    buyCurrency: PropTypes.string,
    buyUnitOfMeasureId: PropTypes.number,
    grinderDestinationCountry: PropTypes.string.isRequired,
    loadSizes: PropTypes.arrayOf(PropTypes.object).isRequired,
    idx: PropTypes.number.isRequired,
    id: PropTypes.number,
    inputProducts: PropTypes.arrayOf(PropTypes.object).isRequired,
    isColdstoreRequired: PropTypes.bool.isRequired,
    isChilled: PropTypes.bool.isRequired,
    isRecurring: PropTypes.bool.isRequired,
    isInternationalOrder: PropTypes.bool.isRequired,
    isInEditMode: PropTypes.bool.isRequired,
    initialValues: PropTypes.shape({
      dateType: PropTypes.string,
      deliveryDate: PropTypes.string,
      lineCounter: PropTypes.number,
      lineIndices: PropTypes.arrayOf(PropTypes.number),
      lines: PropTypes.instanceOf(Object),
      selections: PropTypes.arrayOf(PropTypes.object),
      shipmentDateStart: PropTypes.string,
      shipmentDateEnd: PropTypes.string,
      arrivalDateStart: PropTypes.string,
      arrivalDateEnd: PropTypes.string,
      recurringDateStart: PropTypes.string,
      recurringDateEnd: PropTypes.string,
      preferredEta: PropTypes.string,
      isShipmentDateRequiredPrompt: PropTypes.bool,
      scheduledDeliveryTime: PropTypes.string,
      estimatedDeliveryDate: PropTypes.string,
    }).isRequired,
    onAddPurchaseOrder: PropTypes.func.isRequired,
    onUpdatePurchaseOrder: PropTypes.func.isRequired,
    onRemovePurchaseOrder: PropTypes.func.isRequired,
    packerPlantOriginCountry: PropTypes.string.isRequired,
    packerId: PropTypes.string.isRequired,
    packerPlantId: PropTypes.string.isRequired,
    purchasingOffice: PropTypes.string,
    purchaseOrderIndices: PropTypes.arrayOf(PropTypes.number).isRequired,
    sellCurrency: PropTypes.string,
    sellUnitOfMeasureId: PropTypes.number,
    validationErrors: PropTypes.instanceOf(Object).isRequired,
    requiredFieldsLabels: PropTypes.instanceOf(Object).isRequired,
    packerPlantOptions: PropTypes.arrayOf(PropTypes.object),
    packerPlants: PropTypes.arrayOf(PropTypes.object),
    grinders: PropTypes.arrayOf(PropTypes.object),
    tags: PropTypes.arrayOf(PropTypes.object),
    form: PropTypes.instanceOf(Object),
    packageWeights: PropTypes.arrayOf(PropTypes.Object),
    formFields: PropTypes.instanceOf(Object),
  };

  constructor(props) {
    super(props);

    const {
      initialValues: {
        arrivalDateStart,
        arrivalDateEnd,
        dateType,
        deliveryDate,
        lineCounter,
        lineIndices,
        lines,
        recurringDateStart,
        recurringDateEnd,
        selections,
        shipmentDateStart,
        shipmentDateEnd,
        preferredEta,
        scheduledDeliveryTime,
        estimatedDeliveryDate,
      },
    } = this.props;

    this.state = {
      arrivalDateStart,
      arrivalDateEnd,
      copies: 1,
      dateType,
      dateValidationErrors: {
        errorMsg: null,
      },
      days: {
        mon: 0,
        tue: 0,
        wed: 0,
        thu: 0,
        fri: 0,
        sat: 0,
        sun: 0,
      },
      deliveryDate,
      isShipmentDateRequiredPrompt: false,
      lineCounter,
      lineIndices,
      lines,
      preferredEta,
      estimatedDeliveryDate,
      recurringDateEnd,
      recurringDateStart,
      selections,
      shipmentDateEnd,
      shipmentDateStart,
      showShipment: true,
      tags: [],
      scheduledDeliveryTime,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isInternationalOrder && this.props.isInternationalOrder !== nextProps.isInternationalOrder) {
      this.setState({
        dateType: 'shipment',
      });
    }
    if (nextProps.isRecurring && this.props.isRecurring !== nextProps.isRecurring) {
      this.setState({
        dateType: 'recurring',
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { onUpdatePurchaseOrder, idx } = this.props;
    if (!isEqual(prevState, this.state)) {
      onUpdatePurchaseOrder({ ...this.state, idx });
    }
  }

  handleAddLine = () => {
    const MAX_LINE_COUNT = 8;

    const { lineCounter, lines, lineIndices } = this.state;

    if (lineIndices.length === MAX_LINE_COUNT) return;

    const newLineIndex = lineCounter + 1;
    const newLine = createNewLine(newLineIndex);

    this.setState({
      lineCounter: newLineIndex,
      lines: {
        ...lines,
        [newLineIndex]: newLine,
      },
      lineIndices: [...lineIndices, newLineIndex],
    });
  };

  handleRemoveLine = lineIdx => {
    const { lines, lineIndices } = this.state;

    const updatedLineIndices = lineIndices.filter(idx => idx !== lineIdx);
    delete lines[lineIdx];

    this.setState({
      lines,
      lineIndices: updatedLineIndices,
    });
  };

  handleUpdateLine = (lineIndex, fieldName, value) => {
    const { lines } = this.state;
    this.setState({
      lines: {
        ...lines,
        [lineIndex]: {
          ...lines[lineIndex],
          [fieldName]: value,
        },
      },
    });
  };

  handleValueChange = (fieldName, value) => {
    this.setState({
      [fieldName]: value,
    });
  };

  handleDateValueChange = (title, fieldName, dateString) => {
    const stateCopy = this.state;
    // validation
    let shipmentDateErrors = '';
    if (dateString) {
      shipmentDateErrors = shipmentDateValidations(title, fieldName, dateString, stateCopy);
    }
    // production date [different validation functions for production and shipment as components/view are different]
    const productionDateErrors = productionDateValidations(title, fieldName, dateString, stateCopy);
    this.setState({
      dateValidationErrors: {
        errorMsg: shipmentDateErrors || productionDateErrors || null,
      },
    });

    // Pre-populate end date to a two-week interval for shipment & arrival dates
    let shouldPrePopulate = false;
    let endFieldName = '';
    if (['arrivalDateStart', 'shipmentDateStart'].includes(fieldName)) {
      endFieldName = fieldName.replace('Start', 'End');
      // Only pre-populate field if it is empty
      shouldPrePopulate = !this.state[endFieldName];
    }

    this.setState({
      [fieldName]: dateString,
      ...(fieldName === 'deliveryDate' && { dateType: 'deliveryDate' }),
      ...(shouldPrePopulate && {
        [endFieldName]: moment(dateString).add(2, 'weeks').format('YYYY-MM-DD'),
      }),
    });
  };

  handleShipmentPromptChange = (key, { value }) => {
    if (value) {
      this.setState({
        dateValidationErrors: {
          errorMsg: null,
        },
      });
    }
    this.setState({ isShipmentDateRequiredPrompt: value });
  };

  handleLabelChange = (field, values) => {
    this.setState({ tags: values });
  };

  handlePPUChangeValue = (key, index, value) => {
    const { lines } = this.state;

    if (isNaN(value)) {
      return;
    }

    // format PPU input to 4 decimal places
    const result = correctDecimalRoundingChange(value, 4);

    const line = lines[index];

    this.setState({
      lines: {
        ...lines,
        [index]: {
          ...line,
          [key]: result,
        },
      },
    });
  };

  handlePPURoundingDecimal = (key, index, value) => {
    const { lines } = this.state;

    // format PPU input to 4 decimal places
    const { result, roundedNumber } = correctDecimalRoundingBlur(value, 4);

    if (isNaN(roundedNumber)) return;

    const line = lines[index];

    this.setState({
      lines: {
        ...lines,
        [index]: {
          ...line,
          [key]: result,
        },
      },
    });
  };

  handleOrderIncrement = count => {
    this.setState({
      copies: count,
    });
  };

  handleCopyPurchaseOrder = () => {
    const { onCopyPurchaseOrder, idx } = this.props;
    onCopyPurchaseOrder(idx);
  };

  handleDateTypeChange = value => {
    if (value === 'shipment' || value === 'arrival') {
      this.setState(
        {
          showShipment: value === 'shipment',
          dateType: value,
        },

        () => {
          // can have either arrival or shipment values
          // clear value on counter part of the current toggle if available
          const toggleCounterValue = value === 'shipment' ? 'arrival' : 'shipment';
          const startDate = `${toggleCounterValue}DateStart`;
          const endDate = `${toggleCounterValue}DateEnd`;
          this.setState({
            [startDate]: null,
            [endDate]: null,
          });
        }
      );
    }
  };

  handleRecurringFieldChange = (dayKey, dayCount) => {
    const { days } = this.state;
    this.setState({
      days: {
        ...days,
        [dayKey]: dayCount,
      },
    });
  };

  render() {
    const {
      buyCurrency,
      buyUnitOfMeasureId,
      grinderDestinationCountry,
      id,
      idx,
      inputProducts,
      isChilled,
      isColdstoreRequired,
      isRecurring,
      isInEditMode,
      isInternationalOrder,
      loadSizes,
      onRemovePurchaseOrder,
      packerPlantOriginCountry,
      packerId,
      packerPlantId,
      packerPlantOptions,
      purchasingOffice,
      purchaseOrderIndices,
      validationErrors,
      sellCurrency,
      sellUnitOfMeasureId,
      packerPlants,
      grinders,
      tags,
      form,
      packageWeights,
      requiredFieldsLabels,
      formFields,
    } = this.props;

    const {
      lines,
      lineIndices,
      showShipment,
      arrivalDateStart,
      arrivalDateEnd,
      shipmentDateStart,
      shipmentDateEnd,
      copies,
      days,
      recurringDateStart,
      recurringDateEnd,
      dateValidationErrors,
      preferredEta,
      estimatedDeliveryDate,
      isShipmentDateRequiredPrompt,
    } = this.state;

    const hasAsianGrinder = checkForAsianGrinder(grinderDestinationCountry.toLowerCase());
    const tagsCopy = cloneDeep(tags);
    const labelOptions = tagsCopy.map(l => ({ label: l.value, value: l.value }));

    return (
      <VStack
        align="stretch"
        marginY="25px"
        gap="21px"
        backgroundColor="white"
        paddingY="28px"
        paddingX="26px"
        boxShadow="base"
      >
        <Flex justify="space-between">
          <Box>
            <Text as="p" fontSize="15px" fontWeight="bold">
              Purchase Order no. {id || idx}
            </Text>
            {dateValidationErrors.errorMsg && <Alert status="error" title={dateValidationErrors.errorMsg} />}
          </Box>
          <Flex gap="10px">
            <Button colorScheme="actionSecondary" height="36px" width="187px" onClick={this.handleCopyPurchaseOrder}>
              Create Multiple Order
              <CopyIconSVG />
            </Button>
            {purchaseOrderIndices.length !== 1 && (
              <PopoverRoot placement="bottom-end">
                <PopoverTrigger>
                  <Button colorScheme="red" height="36px">
                    Delete Order <i className="la la-trash-o" />
                  </Button>
                </PopoverTrigger>
                <PopoverCloseTrigger />
                <PopoverContent>
                  <PopoverBody>Are you sure you want to delete this purchase order?</PopoverBody>
                  <PopoverFooter>
                    <Group gap={2}>
                      <Button colorScheme="actionPrimary" onClick={() => onRemovePurchaseOrder(idx)}>
                        Yes
                      </Button>
                    </Group>
                  </PopoverFooter>
                </PopoverContent>
              </PopoverRoot>
            )}
          </Flex>
        </Flex>
        {/* <Flex gap="29px" backgroundColor="card.default" paddingTop="30px" paddingX="29px" paddingBottom="40px"> */}
        <Grid templateColumns="repeat(6, 1fr)" gap="6">
          <CustomFormControl width="100%" label="Number Of Orders" minWidth="150px">
            <NumberSpinnerInput
              showError={get(validationErrors, `pos.${idx}.errors`, []).includes('copies')}
              value={copies}
              onChange={this.handleOrderIncrement}
            />
          </CustomFormControl>
          {/* <!-- AUSTRALIA DOMESTIC FIELDS --> */}
          {!isColdstoreRequired && !isRecurring && (
            <CustomFormControl width="100%">
              <POFormFieldLabel
                label="Delivery Date"
                htmlFor="delivery-date"
                fieldName="deliveryDate"
                className="po-card__label form-control-label"
                isRequired={
                  get(requiredFieldsLabels, `pos.${idx}.errors`, []).includes('deliveryDate') ||
                  intersection(get(requiredFieldsLabels, `pos.${idx}.errors`, []), [
                    'shipmentDateStart',
                    'shipmentDateEnd',
                  ]).length > 0
                }
              />
              <Datetime
                id="delivery-date"
                inputProps={{
                  placeholder: 'Delivery Date',
                  className: 'form-control m-input',
                }}
                closeOnSelect
                value={this.state.deliveryDate}
                onChange={value =>
                  this.handleDateValueChange(
                    'Delivery Date',
                    'deliveryDate',
                    value._isAMomentObject ? value.format('YYYY-MM-DD') : null // eslint-disable-line no-underscore-dangle
                  )
                }
                className={
                  get(validationErrors, `pos.${idx}.errors`, []).includes('deliveryDate') ||
                  intersection(get(validationErrors, `pos.${idx}.errors`, []), ['shipmentDateStart', 'shipmentDateEnd'])
                    .length > 0
                    ? 'error'
                    : ''
                }
                timeFormat={false}
                dateFormat="YYYY-MM-DD"
              />
            </CustomFormControl>
          )}
          {!isColdstoreRequired && !isRecurring && (
            <CustomFormControl width="100%">
              {/* <!-- DELIVERY TIME --> */}
              <POFormFieldLabel
                label="Delivery Time"
                htmlFor="delivery-time"
                className="po-card__label form-control-label"
              />
              <TimePicker
                use12Hours
                format="h:mm a"
                value={this.state.scheduledDeliveryTime && moment(this.state.scheduledDeliveryTime, 'HH:mm')}
                onChange={value => this.handleValueChange('scheduledDeliveryTime', value && value.format('HH:mm'))}
                style={{
                  height: '37px',
                }}
              />
            </CustomFormControl>
          )}
          <Box width="100%">
            {/* tags from form fields */}
            <SelectField
              {...{
                label: 'Tags',
                className: 'po-card__select',
                isClearable: true,
                disabled: !isInEditMode,
                isSearchable: true,
                closeMenuOnSelect: false,
                removeSelected: true,
                isMulti: true,
                name: 'select-tags',
                defaultValue: tags,
                onChange: e =>
                  this.handleLabelChange(
                    'tags',
                    e.map(lbl => lbl.value)
                  ),
                options: labelOptions,
              }}
            />
          </Box>
          {/* <!-- AUSTRALIA INTERNATIONAL FIELDS --> */}
          {isColdstoreRequired && !isRecurring && (
            <DateSection
              {...{
                arrivalDateStart,
                arrivalDateEnd,
                shipmentDateStart,
                shipmentDateEnd,
                showShipment,
                onToggleChange: this.handleDateTypeChange,
                onDateChange: this.handleDateValueChange,
                isInEditMode,
                requiredFields: validationErrors,
                purchaseOrderIndex: idx,
                isShipmentDateRequiredPrompt,
                onChangeFieldValue: this.handleShipmentPromptChange,
                requiredFieldsLabels,
              }}
            />
          )}

          {/* <!-- Estimated Delivery Date --> */}
          {formFields?.find(field => field?.type === 'show_estimated_delivery_date' && field?.value === '1') &&
            isInternationalOrder && (
              <CustomFormControl>
                <POFormFieldLabel
                  label="Estimated Delivery Date"
                  htmlFor="estimatedDeliveryDate"
                  className="po-card__label form-control-label"
                />
                <Datetime
                  id="estimatedDeliveryDate"
                  inputProps={{
                    className: 'form-control m-input',
                    disabled: !isInEditMode,
                    placeholder: 'Estimated Delivery Date',
                  }}
                  closeOnSelect
                  value={estimatedDeliveryDate || null}
                  onChange={value =>
                    this.handleDateValueChange(
                      'Estimated Delivery Date',
                      'estimatedDeliveryDate',
                      value._isAMomentObject ? value.format('YYYY-MM-DD') : null // eslint-disable-line no-underscore-dangle
                    )
                  }
                  timeFormat={false}
                  dateFormat="YYYY-MM-DD"
                />
              </CustomFormControl>
            )}

          {isInternationalOrder && !isRecurring && hasAsianGrinder && (
            <CustomFormControl>
              {/* <!-- PREFERRED ETA --> */}
              <POFormFieldLabel
                label="Preferred ETA"
                htmlFor="preferredEta"
                className="po-card__label form-control-label"
              />
              <Datetime
                id="preferredEta"
                inputProps={{
                  className: 'form-control m-input',
                  disabled: !isInEditMode,
                  placeholder: 'Preferred ETA',
                }}
                closeOnSelect
                value={preferredEta || null}
                onChange={value =>
                  this.handleDateValueChange(
                    'Preferred ETA',
                    'preferredEta',
                    value._isAMomentObject ? value.format('YYYY-MM-DD') : null // eslint-disable-line no-underscore-dangle
                  )
                }
                timeFormat={false}
                dateFormat="YYYY-MM-DD"
              />
            </CustomFormControl>
          )}
          {/* <!-- RECURRING ORDER FIELDS --> */}
          {!isInternationalOrder && isRecurring && (
            <Box>
              <InternationalOrderRecurringFields
                {...{
                  isInEditMode,
                  purchaseOrderIndex: idx,
                  validationErrors,
                  days,
                  dateStart: recurringDateStart,
                  dateEnd: recurringDateEnd,
                  onDateChange: this.handleDateValueChange,
                  onDayChange: this.handleRecurringFieldChange,
                }}
              />
            </Box>
          )}
        </Grid>
        {/* <!-- INTERNATIONAL ORDER LINES --> */}
        <InternationalOrderLines
          {...{
            buyCurrency,
            buyUnitOfMeasureId,
            grinderDestinationCountry,
            inputProducts,
            isChilled,
            isInEditMode,
            hasExpectedProductionDate: isInternationalOrder,
            loadSizes,
            lines,
            lineIndices,
            onAddLine: this.handleAddLine,
            onUpdateLine: this.handleUpdateLine,
            onRemoveLine: this.handleRemoveLine,
            onPPUChangeValue: this.handlePPUChangeValue,
            onPPURoundingDecimal: this.handlePPURoundingDecimal,
            packerPlantOriginCountry,
            packerId,
            packerPlantId,
            packerPlantOptions,
            purchasingOffice,
            purchaseOrderIndex: idx,
            purchaseOrderId: id,
            requiredFields: validationErrors,
            sellCurrency,
            sellUnitOfMeasureId,
            form,
            packerPlants,
            grinders,
            shipmentDateEnd,
            tags,
            packageWeights,
            requiredFieldsLabels,
          }}
        />
      </VStack>
    );
  }
}

const mapStateToProps = state => {
  const result = findEntitiesInState(state, {
    form_fields: 'form_fields',
  });
  return convertToCamelCase(result);
};

export default connect(mapStateToProps)(PurchaseOrderCard);
