import moment from 'moment';
import React, { Component } from 'react';
import Datetime from '@/components/core/DatePicker';
// Local Deps
import { postPackerOfferForm } from '../../../../actions/actions_packer_offer';
import { validatePackerOfferForm } from '../../../../functions';
import ValidationErrors from '../../validators/ValidationErrors';
import SelectField from '../../../basic/SelectField';
import FinalizeMessage from '../FinalizeMessage';
import PackerOfferFormSummary from '../PackerOfferFormSummary';
import PackerOfferFormLines from './PackerOfferFormLines';

class PackerOfferForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validationErrors: [],
      dateWarnings: [],
      finalizeSuccessMsg: false,
      form: {
        total_count: 1,
        end_date: moment().format('YYYY-MM-DD'),
        start_date: moment().format('YYYY-MM-DD'),
        packer_id: '',
        delivery_start_date: '',
        delivery_end_date: '',
        packer_plant_id: null,
        name: '',
        price_source: '',
        lines: [
          {
            input_product_uid: 0,
            number_of_bins: 0,
            price_per_unit: 0,
            quantity: 0,
            fabrication_date: '',
            price_type: 'spot',
          },
        ],
      },
    };
    this.onFabricationDateChange = this.onFabricationDateChange.bind(this);
    this.onLineValueChange = this.onLineValueChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    this.setState({
      finalizeSuccessMsg: nextProps.added_packer_offers_count > 0,
    });
  }

  componentDidMount() {
    window.addEventListener('keypress', this.removeAllWarnings, false);
  }

  componentWillUnmount() {
    window.removeEventListener('keypress', this.removeAllWarnings);
  }

  removeAllWarnings = () => {
    this.setState({ dateWarnings: [] });
  };

  resetForm = () => {
    this.setState({
      validationErrors: [],
      dateWarnings: [],
      finalizeSuccessMsg: false,
      form: {
        total_count: 1,
        end_date: moment().format('YYYY-MM-DD'),
        start_date: moment().format('YYYY-MM-DD'),
        packer_id: '',
        delivery_start_date: '',
        delivery_end_date: '',
        price_source: '',
        fabrication_date: '',
        lines: [
          {
            input_product_uid: 0,
            number_of_bins: 0,
            price_per_unit: 0,
            quantity: 0,
            fabrication_date: '',
            price_type: 'spot',
          },
        ],
      },
    });
  };

  submitPackerOffer = () => {
    const form = Object.assign({}, this.state.form);
    const DEFAULT_BIN_SIZE = 2000;

    let totalNumberOfBins = 0;
    form.lines.forEach(line => {
      delete line.input_product;
      const quantityNumber = parseFloat(line.quantity);
      let roundedNumber = Math.ceil(quantityNumber / DEFAULT_BIN_SIZE) * DEFAULT_BIN_SIZE;
      roundedNumber = parseInt(roundedNumber);
      line.quantity = roundedNumber;
      line.number_of_bins = roundedNumber / DEFAULT_BIN_SIZE;

      totalNumberOfBins += line.number_of_bins;
    });

    const validationErrors = validatePackerOfferForm(this.state.form);
    this.setState({ validationErrors });

    if (validationErrors.length === 0) {
      if (form.packer_plant_id) {
        form.packer_id = null;
        form.packer_plant_id = parseInt(form.packer_plant_id.split('_')[0]);
      } else {
        form.packer_plant_id = null;
        form.packer_id = parseInt(form.packer_id.split('_')[0]);
      }

      this.props.dispatch(postPackerOfferForm(form, this.state.form.total_count, this.props.token));
    }
  };

  addLine = () => {
    const stateCopy = Object.assign({}, this.state);

    if (stateCopy.form.lines.length < 8) {
      const newPackerOfferLine = {
        input_product_uid: 0,
        number_of_bins: 0,
        price_per_unit: 0,
        quantity: 0,
        fabrication_date: '',
        price_type: 'spot',
      };

      stateCopy.form.lines.push(newPackerOfferLine);
      this.setState(stateCopy);
    }
  };

  onFabricationDateChange = (index, e) => {
    if (e._isAMomentObject && e.isBefore(moment().subtract(14, 'days'))) {
      const currentWarnings = Object.assign([], this.state.dateWarnings);
      currentWarnings.push(index);
      this.setState({ dateWarnings: currentWarnings });
    }
    this.onLineValueChange('fabrication_date', e, index);
  };

  removeLine = index => {
    const formCopy = Object.assign({}, this.state.form);
    formCopy.lines.splice(index, 1);
    this.setState({ form: formCopy });
  };

  onValueChange = (fieldName, value) => {
    const newFormState = Object.assign({}, this.state.form);
    newFormState[fieldName] = value;

    if (fieldName === 'start_date') {
      newFormState.end_date = value;
    }

    this.setState({ form: newFormState });
  };

  onOfferFromChange(e) {
    const EST_PREFIX = 'Est. Group - ';
    if (e === null) {
      return;
    }

    const newFormState = Object.assign({}, this.state.form);
    newFormState.packer_id = e.value;

    if (!e.label.startsWith(EST_PREFIX)) {
      newFormState.packer_plant_id = e.value;
    } else {
      newFormState.packer_plant_id = null;
    }

    this.setState({ form: newFormState });
  }

  removeWarning = index => {
    const stateCopy = Object.assign({}, this.state);
    stateCopy.dateWarnings.splice(stateCopy.dateWarnings.indexOf(index));
    this.setState(stateCopy);
  };

  onLineValueChange = (fieldName, value, index) => {
    const copyFormState = Object.assign({}, this.state.form);
    copyFormState.lines[index][fieldName] = value;

    if (fieldName === 'input_product_uid') {
      copyFormState.lines[index].input_product = this.resolveInputProduct(copyFormState.lines[index][fieldName]);
    }

    this.setState({ form: copyFormState });
  };

  resolveInputProduct(inputProductUid) {
    const inputProduct = this.props.input_products.filter(item => {
      return item.uid === inputProductUid;
    });
    return inputProduct.length ? inputProduct[0] : null;
  }

  render() {
    const { form, validationErrors } = this.state;

    const offerDateProps = {
      placeholder: 'Offer Date',
      className: 'form-control m-input',
    };

    const deliveryStartDateInputProps = {
      placeholder: 'Delivery Start Date',
      className: 'form-control m-input',
    };

    const deliveryEndDateInputProps = {
      placeholder: 'Delivery End Date',
      className: 'form-control m-input',
    };

    const offerFromOptions = [];

    this.props.packer.forEach(function (element) {
      offerFromOptions.push({
        value: `${element.id}_pg`,
        label: `Est. Group - ${element.name}`,
      });
    });

    this.props.packer_plants.forEach(function (element) {
      offerFromOptions.push({
        value: `${element.uid}_p`,
        label: element.name,
        packer_plant: true,
      });
    });

    const offerSourceOptions = [];
    this.props.offer_source.forEach(function (element) {
      const capitalized = element.value.charAt(0).toUpperCase() + element.value.slice(1);
      offerSourceOptions.push({ value: element.value, label: capitalized });
    });

    return (
      <div className="m-grid__item m-grid__item--fluid m-wrapper" style={{ padding: '30px' }}>
        <div className="m-content">
          <div className="row">
            <div className="col-xl-12">
              <div className="m-portlet">
                <div>
                  <h2>Packer Offer Form</h2>
                  <hr />
                  <div className="row">
                    <div className="col-sm-2">
                      <label htmlFor="offer_end_date">Offer Date</label>
                      <Datetime
                        id="offer_end_date"
                        inputProps={offerDateProps}
                        closeOnSelect
                        value={form.end_date}
                        onChange={e =>
                          this.onValueChange('start_date', e._isAMomentObject ? e.format('YYYY-MM-DD') : e)
                        }
                        disabled
                        timeFormat={false}
                        dateFormat="YYYY-MM-DD"
                      />
                    </div>
                    <div className="col-sm-3">
                      <SelectField
                        label="Offer From"
                        name="select-packer"
                        defaultValue={form.packer_id}
                        options={offerFromOptions}
                        placeholder="Offer From"
                        onChange={e => {
                          this.onOfferFromChange(e || null);
                        }}
                      />
                    </div>
                    <div className="col-sm-2" />
                  </div>
                  <div className="row" style={{ paddingTop: '20px' }}>
                    <div className="col-sm-2">
                      <label htmlFor="delivery_start_date">Delivery Start Date</label>
                      <Datetime
                        id="delivery_start_date"
                        inputProps={deliveryStartDateInputProps}
                        closeOnSelect
                        value={form.delivery_start_date}
                        onChange={e =>
                          this.onValueChange('delivery_start_date', e._isAMomentObject ? e.format('YYYY-MM-DD') : e)
                        }
                        timeFormat={false}
                        dateFormat="YYYY-MM-DD"
                      />
                    </div>
                    <div className="col-sm-2">
                      <label htmlFor="delivery_end_date">Delivery End Date</label>
                      <Datetime
                        id="delivery_end_date"
                        inputProps={deliveryEndDateInputProps}
                        closeOnSelect
                        value={form.delivery_end_date}
                        onChange={e =>
                          this.onValueChange('delivery_end_date', e._isAMomentObject ? e.format('YYYY-MM-DD') : e)
                        }
                        timeFormat={false}
                        dateFormat="YYYY-MM-DD"
                      />
                    </div>
                    <div className="col-sm-3" />
                    <div className="col-sm-2">
                      <SelectField
                        label="Offer Source"
                        name="select-input-product"
                        defaultValue={form.price_source}
                        options={offerSourceOptions}
                        onChange={e => {
                          this.onValueChange('price_source', e ? e.value : null);
                        }}
                      />
                    </div>
                  </div>
                  `
                </div>
                <hr />
                <PackerOfferFormLines
                  addLine={this.addLine}
                  removeLine={this.removeLine}
                  onLineValueChange={this.onLineValueChange}
                  removeWarning={this.removeWarning}
                  onFabricationDateChange={this.onFabricationDateChange}
                  dateWarnings={this.state.dateWarnings}
                  lines={this.state.form.lines}
                  input_products={this.props.input_products}
                  price_types={this.props.price_types}
                />
                <PackerOfferFormSummary
                  total_count={this.state.form.total_count}
                  lines={this.state.form.lines}
                  onValueChange={this.onValueChange}
                />
                <FinalizeMessage finalizeSuccessMsg={this.state.finalizeSuccessMsg} reset={this.resetForm} />
                <ValidationErrors errors={validationErrors} />
                <button className="btn btn-info pull-right" onClick={this.submitPackerOffer}>
                  Submit Offer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PackerOfferForm;
